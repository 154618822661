import { Alert, AlertIcon } from "@chakra-ui/react";
import { useSelector } from "react-redux";

const AppUpdateAvailableMessage = () => {
  const { appUpdateAvailable } = useSelector((store) => store.defaultReducer);

  return appUpdateAvailable ? (
    <>
      <Alert status="info">
        <AlertIcon />
        New updates are available and will be used when all tabs for this page
        are closed.
      </Alert>
    </>
  ) : (
    <></>
  );
};

export default AppUpdateAvailableMessage;
