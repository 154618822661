import {
  Box,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { formatNumber } from "../../../store/helpers";
import LogsPerUrlLineGraph from "../charts/LogsPerUrlLineGraph";

const LogsPerUrlTable = ({ data, branchName }) => {
  const {
    name,
    total,
    successful,
    unsuccessful,
    pctSuccessful,
    pctUnsuccessful,
    datesData,
  } = data;

  return (
    <Box>
      <h3>{name}</h3>
      <Tag>
        <p className="branchTag">{branchName} Branch</p>
      </Tag>
      <Table className="stripedTable" size="sm">
        <Thead>
          <Tr>
            <Th borderRight="1px solid #dadce0">Total Logs</Th>
            <Th>Successful</Th>
            <Th>Unsuccessful</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td borderRight="1px solid #dadce0">
              <Text color={"var(--blue)"}>{formatNumber(total)}</Text>
            </Td>
            <Td>
              <Text color={"var(--green)"}>
                {formatNumber(successful)}

              </Text>
              <Text
                  color="gray.500"
                  fontWeight="semibold"
                  fontSize="xs"
                  textTransform="uppercase"
                >
                  ({pctSuccessful.toFixed(2)}%)
                </Text>
            </Td>
            <Td>
              <Text cursor="pointer" color={"var(--red)"}>
                {formatNumber(unsuccessful)}

              </Text>
              <Text
                  color="gray.500"
                  fontWeight="semibold"
                  fontSize="xs"
                  textTransform="uppercase"
                >
                  ({pctUnsuccessful.toFixed(2)}%)
                </Text>
            </Td>
          </Tr>
          <Tr>
            <Td colSpan={3}>
              <LogsPerUrlLineGraph chart={datesData} chartHeight="200px" />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default LogsPerUrlTable;
