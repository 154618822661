import dayjs from "dayjs";

// loop through services and add a property to each service object that indicates if the service has children or not
export const checkServiceHasChildren = (services) => {
  return services.map((a) => {
    a.hasChildren = services.filter((b) => b.parent === a.id).length ? 1 : 0;
    return a;
  });
};

// loop through ticket array and add a property to each ticket object that indicates if the service exists or not
export const checkServiceExistsForTickets = (tickets, services) => {
  return tickets.map((a) => {
    a.serviceExistStatus = services.find((b) => b.id === a.serviceId) ? 1 : 0;
    return a;
  });
};

// find all services that have been delete but were present in the tickets array
export const findDeletedServices = (ticketsUpdated) => {
  return ticketsUpdated
    .map((a) => {
      return !a.serviceExistStatus ? a : null;
    })
    .filter(Boolean);
};

export const getReadableKeyFromObject = (obj, value) => {
  let key = Object.keys(obj).find((key) => obj[key] === value);
  return key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
};

export const getNonWorkDays = (workWeek) => {
  return [0, 1, 2, 3, 4, 5, 6].filter((a) => !workWeek.includes(a));
};

export const getNonWorkDates = (date, nonWorkDays, daysInMonth) => {
  const nonWorkDates = [];
  for (let dayIndex = 1; dayIndex <= daysInMonth; dayIndex++) {
    const currentDay = date.date(dayIndex);
    if (nonWorkDays.includes(currentDay.day())) {
      nonWorkDates.push(currentDay.format("D"));
    }
  }
  return nonWorkDates;
};

//
export const mapHasData = (map) => {
  const maxLength = Math.max(...Object.values(map).map((arr) => arr.length));

  return maxLength > 0;
};

export const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600).toFixed(0);
  const minutes = Math.floor((seconds % 3600) / 60).toFixed(0);
  const remainingSeconds = (seconds % 60).toFixed(0);
  const formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
    remainingSeconds
  )}`;
  return formattedTime;
};
function padZero(num) {
  return num < 10 ? `0${num}` : num;
}

export const formatNumber = (number) => {
  return number.toLocaleString();
};

//generate dates array between provided timestamps
export const generateDateArray = (startTimestamp, endTimestamp) => {
  const startDate = dayjs.unix(startTimestamp);
  const endDate = dayjs.unix(endTimestamp);

  const datesArray = [];
  let currentDate = startDate;

  while (currentDate.isBefore(endDate)) {
    datesArray.push(currentDate.format("MM-DD-YYYY"));
    currentDate = currentDate.add(1, "day");
  }

  return datesArray;
};

//compare two arrays
export const arraysAreEqual = (arr1, arr2) => {
  return (
    arr1.length === arr2.length && arr1.every((elem, i) => elem === arr2[i])
  );
};


