import _ from "lodash";
import { useSelector } from "react-redux";
import URLStatsTable from "../../components/stats/tables/URLStatsTable";
import { Box } from "@chakra-ui/react";
import FilterForm from "../../components/FilterForm";
import Loader from "../../components/loader";
import { RELIABLE_ENDPOINTS } from "../../store/constants";

const LogsPerURL = () => {
  const { urls, logs, loading } = useSelector((store) => store.defaultReducer);

  const logsClone = _.cloneDeep(logs);
  const urlsClone = _.cloneDeep(urls);
  const dataObj = urlsClone.reduce((acc, { url, name }) => {
    acc[url] = { name };
    return acc;
  }, {});

  Object.keys(dataObj).forEach((url) => {
    dataObj[url]["total"] =
      logsClone.filter((log) => String(log.url) === String(url))?.length || 0;
    dataObj[url]["numSuccessful"] =
      logsClone.filter(
        (log) =>
          log.sscStatus?.toLowerCase() === "success" &&
          String(log.url) === String(url)
      ).length || 0;
    const isReliableEndpoint = RELIABLE_ENDPOINTS.includes(url);

    if (isReliableEndpoint) {
      const successfulStatusCodes = [200, 201, 202];

      dataObj[url]["numSuccessful"] = logsClone.filter(
        (log) =>
          String(log.url) === String(url) &&
          successfulStatusCodes.includes(Number(log.statusCode))
      ).length;
    }
    dataObj[url]["numUnsuccessul"] =
      dataObj[url]["total"] - dataObj[url]["numSuccessful"];
    dataObj[url]["pctSuccessful"] =
      (dataObj[url]["numSuccessful"] / dataObj[url]["total"]) * 100 || 0;

    dataObj[url]["pctUnsuccessul"] =
      (dataObj[url]["numUnsuccessul"] / dataObj[url]["total"]) * 100 || 0;
  });

  return (
    <>
      <Box>
        <FilterForm />
        <h1>Number of logs</h1>

        {loading ? <Loader /> : <URLStatsTable dataObj={dataObj} />}
      </Box>
    </>
  );
};

export default LogsPerURL;
