import { connect } from "react-redux";
import { Box, Flex, Img } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import {
  updateReportRange,
  updateOrganizationId,
} from "../store/actions/defaultAction";
import Logo from "../assets/media/logos/logo.svg";

import Nav from "./sidebar/Nav";

function Sidebar(props) {
  const { organizationId } = useSelector((state) => state.defaultReducer);

  return (
    <Box
      className="sidebar"
      width="250px"
      backgroundColor="gray.200"
      p={4}
      position="fixed"
      height="100vh"
      overflowY="auto" // Add this line for the scrollbar effect
    >
      <Flex h="20" alignItems="center" mx="8" mt={5}>
        <Img src={Logo} boxSize="8rem" />
      </Flex>

      <Nav />
    </Box>
  );
}

export default connect(null, {
  updateReportRange,
  updateOrganizationId,
})(Sidebar);
