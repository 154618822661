import { connect } from "react-redux";
import { NavLink } from "react-router-dom"; // Use the appropriate router package for your application
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { updateAppStatus } from "../../store/actions/authActions";

import { APP_STATUSES, navLinks } from "../../store/constants";
import { useEffect } from "react";

function Nav() {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(updateAppStatus(APP_STATUSES.LOGGED_OUT));
  };

  const changeBb = (item) => {
    let elements = document.getElementsByClassName("navItem_");

    for (const element of elements) {
      element.classList.remove("navItemActive");
    }

    item?.classList?.toggle("navItemActive");
  };
  useEffect(() => {
    //Somehow the dom is not ready especially on login, hence the setTimeout
    setTimeout(() => {
      let parentElem = document.getElementsByClassName("active")[0];
      if (!parentElem) {
        //Logs is the index
        parentElem = document.getElementsByClassName("navItem_")[0];
      }
      const navItem = parentElem.querySelector(".navItem_");
      changeBb(navItem);
    }, 0);
  }, []);

  return (
    <Box className="navigation">
      <VStack spacing={4} align="stretch" pt={10}>
        {navLinks
          .find((a) => a.name === "logs")
          ?.links.map((a, i) => {
            return (
              <Box key={i} mt="10px">
                <Text fontSize="sm" fontWeight="bold" color="gray.500">
                  {a.name}
                </Text>
                <VStack spacing={2} align="stretch" pt={2}>
                  {a.links.map((b, j) => {
                    return (
                      <NavLink key={j} to={b.link} activeclassname="active">
                        <Box>
                          <Flex
                            align="center"
                            className="navItem_"
                            onClick={(e) => changeBb(e.target)}
                            p="4"
                            mx="4"
                            borderRadius="lg"
                            role="group"
                            cursor="pointer"
                            _hover={{
                              bg: "var(--darkMaroon)",
                            }}
                          >
                            <Icon
                              mr="4"
                              fontSize="16"
                              as={b.icon}
                            />
                            {b.name}
                          </Flex>
                        </Box>
                      </NavLink>
                    );
                  })}
                </VStack>
              </Box>
            );
          })}
        <Divider />
      </VStack>
    </Box>
  );
}

export default connect(null, null)(Nav);
