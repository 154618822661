import { ACTION_TYPES } from "../actions/types";
import dayjs from "dayjs";

const initialState = {
  setupStatus: -1, // whether the app is setup or not. -1=not done yet, 0=failed, 1=success

  organizations: [],
  users: [],
  branches: [],
  url: "",
  urls: [],
  logs: [],
  employers: [],
  selectedEmployer: {},
  appUpdateAvailable: false,

  // filter
  organizationId: "D4tJC4RLy7oBWsBTzPGP",
  branchId: "",
  employerNumber: "",
  dateRange: [
    dayjs().subtract(1, "month").startOf("month").unix(),
    dayjs().subtract(1, "month").endOf("month").unix(),
  ],

  // settings
  hoursPerDay: 8,
  loading: false,
  workWeek: [1, 2, 3, 4, 5], // sunday=0, monday=1, tuesday=2, wednesday=3, thursday=4, friday=5, saturday=6
  showEmptyDataSets: 0, // 1=show , 0=hide,
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case "GET_TICKETS":
      return {
        ...state,
        tickets: action.payload,
        loading: false,
      };
    case "GET_SERVICES":
      return {
        ...state,
        services: action.payload,
      };
    case "GET_SERVICES_SUBSCRIPTIONS":
      return {
        ...state,
        servicesSubscriptions: action.payload,
      };
    case "GET_USERS":
      return {
        ...state,
        users: action.payload,
      };
    case "GET_BRANCHES":
      return {
        ...state,
        branches: action.payload,
      };

    case "SET_DATE_RANGE":
      return {
        ...state,
        dateRange: action.payload,
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case ACTION_TYPES.UPDATE_APP_STATUS:
      return {
        ...state,
        appStatus: action.payload,
      };
    case "UPDATE_REPORT_RANGE":
      return {
        ...state,
        reportRange: action.payload,
      };

    case ACTION_TYPES.UPDATE_REPORT_DURATION:
      return {
        ...state,
        reportDuration: action.payload,
      };

    case "GET_ORGANIZATIONS":
      return {
        ...state,
        organizations: action.payload,
      };

    case "UPDATE_ORGANIZATION_ID":
      return {
        ...state,
        organizationId: action.payload,
      };

    case "UPDATE_BRANCH_ID":
      return {
        ...state,
        branchId: action.payload,
      };

    case ACTION_TYPES.SET_LOGS:
      // sort logs desc by created desc
      return {
        ...state,
        logs: action.payload.sort((a, b) => b.created - a.created),
      };

    case ACTION_TYPES.SET_EMPLOYERS:
      // sort employer asc by field tradeName
      return {
        ...state,
        employers: action.payload
          .filter((employer) => employer.tradeName)
          .sort((a, b) => a.tradeName.localeCompare(b.tradeName)),
      };

    case ACTION_TYPES.SET_LOG:
      return {
        ...state,
        log: action.payload,
      };

    case ACTION_TYPES.SET_URLS:
      return {
        ...state,
        urls: action.payload,
      };

    case ACTION_TYPES.UPDATE_URL:
      return {
        ...state,
        url: action.payload,
      };

    case ACTION_TYPES.UPDATE_EMPLOYER:
      return {
        ...state,
        employerNumber: action.payload,
      };

    case ACTION_TYPES.SET_APP_UPDATE_AVAILABLE:
      return {
        ...state,
        appUpdateAvailable: action.payload,
      };

    default:
      return state;
  }
}
