import { ACTION_TYPES } from "../actions/types";
import dayjs from "dayjs";

const initialState = {
  receipts: [],
  paymentsConfirmations: [],
  paymentsData: [],
  paymentData: {},
  referenceNumber: "",
  paymentsLoading: false,
};

export default function paymentsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_RECEIPTS:
      return {
        ...state,
        receipts: action.payload,
      };

    case ACTION_TYPES.FETCH_PAYMENTS_CONFIRMATIONS:
      return {
        ...state,
        paymentsConfirmations: action.payload,
      };

    case ACTION_TYPES.FETCH_PAYMENTS_DATA:
      return {
        ...state,
        paymentsData: action.payload,
      };

    case ACTION_TYPES.UPDATE_REFERENCE_NUMBER:
      return {
        ...state,
        referenceNumber: action.payload,
      };

    case ACTION_TYPES.UPDATE_PAYMENT_DATA:
      return {
        ...state,
        paymentData: action.payload,
      };

    case ACTION_TYPES.UPDATE_PAYMENTS_LOADING:
      return {
        ...state,
        paymentsLoading: action.payload,
      };

    default:
      return state;
  }
}
