import { ImStatsBars } from "react-icons/im";
import { FaLink } from "react-icons/fa6";
import { FaMoneyBill } from "react-icons/fa";

export const version = "1.8.22";
export const LEVELS = Object.freeze({ ORGANIZATION: "0", BRANCH: "1" });
export const completed = 3;
export const noShow = 2;
export const transferred = 1;

export const APP_STATUSES = Object.freeze({
  LOGGED_IN: 1,
  PEDING_LOGIN: 2,
  LOGGED_OUT: 0,
});

//those with proper response codes
export const RELIABLE_ENDPOINTS = [
  "https://prd-registrations.ssc.org.na:8100/ssc/ei/v1/ops/sl/paycon",
];

export const SUCCESS_STATUS_CODES = [200, 201, 202];

export const navLinks = Object.freeze([
  {
    name: "logs",
    links: [
      {
        name: "",
        links: [
          {
            name: "Logs",
            link: "/",
            icon: ImStatsBars,
          },
          {
            name: "Logs Per URL",
            link: "/logs-per-url/",
            icon: FaLink,
          },

          {
            name: "Logs Per Branch",
            link: "/logs-per-branch/",
            icon: ImStatsBars,
          },


          {
            name: "Payments",
            link: "/payments/",
            icon: FaMoneyBill,
          },
        ],
      },
    ],
  },
]);
