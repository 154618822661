import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  HStack,
  Text,
} from "@chakra-ui/react";

//   actions

import { resetDrawer } from "../../store/actions/miscAction";
// components
import PaymentsCard from "../payments/PaymentsCard";

function DrawerComponent(props) {
  const dispatch = useDispatch();

  const { drawer } = useSelector((state) => state.miscReducer);
  const { title, content, isOpen } = drawer;

  const onClose = () => {
    dispatch(resetDrawer());
  };

  const Components = {
    PaymentsCard: <PaymentsCard id={content.id}/>,
  };

  return (
    <Drawer onClose={onClose} isOpen={Boolean(isOpen)} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader mt="50px">
          <Text fontSize="24px" fontWeight={400} color="var(--black)">
            {title}
          </Text>
        </DrawerHeader>
        <DrawerBody>{Components[content.component]}</DrawerBody>

        <DrawerFooter>
          <HStack justifyContent="center" width="full">
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerComponent;
