import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ACTION_TYPES } from "./types";
import { APP_STATUSES } from "../constants";
import Cookies from "js-cookie";

axios.defaults.baseURL = "https://cx.api.averly.com.na";

export const fetchData = async (
  endpoint,
  params,
  singleResponse,
  dispatch,
  actionType
) => {
  try {
    // Retrieve JWT token from cookies
    const accessToken = Cookies.get("accessToken");

    const res = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${String(accessToken)}`,
      },
    });
    const payload = singleResponse
      ? res.data.length
        ? res.data[0]
        : {}
      : res.data;
    dispatch({ type: actionType, payload });
  } catch (err) {
    if (err?.response?.status === 401) {
      dispatch({
        type: ACTION_TYPES.UPDATE_APP_STATUS,
        payload: APP_STATUSES.LOGGED_OUT,
      });
    }
    console.error(`Error fetching data from ${endpoint}:`, err);
    throw err;
  }
};

export const fetchCompleteLog = async (dispatch, logId) => {
  const endpoint = `/ss/logs/id/${logId}`;
  try {
    // Retrieve JWT token from cookies
    const accessToken = Cookies.get("accessToken");

    const res = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${String(accessToken)}`,
      },
    });
    const singleResponse = 0;
    const payload = singleResponse
      ? res.data.length
        ? res.data[0]
        : {}
      : res.data;

    return payload;
  } catch (err) {
    if (err?.response?.status === 401) {
      dispatch({
        type: ACTION_TYPES.UPDATE_APP_STATUS,
        payload: APP_STATUSES.LOGGED_OUT,
      });
    }
    console.error(`Error fetching data from ${endpoint}:`, err);
    return null;
  }
};

// GET_USERS
export const getUsers = (organizationId, branchId) => async (dispatch) => {
  const endpoint = "/iam/users/";
  const payload = {
    organizationId,
    branchId,
  };
  const actionType = "GET_USERS";
  const singleResponse = 0;
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};
// GET_BRANCHES
export const getBranches = (organizationId) => async (dispatch) => {
  const endpoint = "/admin/branches/";
  const payload = {
    organizationId,
  };
  const actionType = "GET_BRANCHES";
  const singleResponse = 0;
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};

// search tickets
export const setDateRange = (dateRange) => async (dispatch) => {
  dispatch({
    type: "SET_DATE_RANGE",
    payload: dateRange,
  });
};

export const updateReportRange = (payload) => (dispatch) => {
  dispatch({
    type: "UPDATE_REPORT_RANGE",
    payload,
  });
};

export const updateOrganizationId = (payload) => (dispatch) => {
  dispatch({
    type: "UPDATE_ORGANIZATION_ID",
    payload,
  });

  dispatch(getBranches(payload));
  dispatch(getUsers(payload));
};

export const updateBranchId =
  ({ branchId }) =>
  (dispatch) => {
    dispatch({
      type: "UPDATE_BRANCH_ID",
      payload: branchId,
    });
  };

// get logs
export const getLogs =
  (dateRange = null, branchId = null, employerNumber = null, url = null) =>
  async (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true }); //This could go directly to getData
    const endpoint = "/ss/logs/";
    const payload = {
      // organizationId,
      createdFrom: dateRange
        ? dateRange[0]
        : dayjs().subtract(1, "month").startOf("month").unix(),
      createdTo: dateRange ? dateRange[1] : dayjs().unix(),
      branchId,
      employerNumber,
      url,
    };
    const actionType = ACTION_TYPES.SET_LOGS;
    const singleResponse = 0;
    toast.info("Fetching logs");
    dispatch(getEmployers());
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
    dispatch({ type: "SET_LOADING", payload: false });
  };

// get employers
export const getEmployers = () => async (dispatch) => {
  const endpoint = "/ss/employers";
  const payload = {};
  const actionType = ACTION_TYPES.SET_EMPLOYERS;
  const singleResponse = 0;
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};

export const getUrls = () => async (dispatch) => {
  const endpoint = "/ss/urls";
  const payload = {};
  const actionType = ACTION_TYPES.SET_URLS;
  const singleResponse = 0;
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};

export const updateUrl =
  ({ url }) =>
  (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_URL,
      payload: url,
    });
  };

export const updateEmployer = (employerNumber) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_EMPLOYER,
    payload: employerNumber,
  });
};

export const updateAppUpdateAvailable = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_APP_UPDATE_AVAILABLE,
    payload,
  });
};

export const updateLoading = (payload) => (dispatch) => {
  dispatch({
    type: "SET_LOADING",
    payload,
  });
};
