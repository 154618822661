// Auth
export const FETCH_CLOUD_ORGANIZATIONS = "FETCH_CLOUD_ORGANIZATIONS";

//Add all action types here
export const ACTION_TYPES = Object.freeze({
  UPDATE_REPORT_DURATION: "UPDATE_REPORT_DURATION",
  UPDATE_APP_STATUS: "UPDATE_APP_STATUS",
  SET_AUTH_LOADING: "SET_AUTH_LOADING",
  SET_LOGS: "SET_LOGS",
  SET_LOG: "SET_LOG",
  SET_EMPLOYERS: "SET_EMPLOYERS",
  SET_URLS: "SET_URLS",
  UPDATE_URL: "UPDATE_URL",
  UPDATE_EMPLOYER: "UPDATE_EMPLOYER",
  SET_USER_DATA: "SET_USER_DATA",
  SET_USER_ID: "SET_USER_ID",
  SET_APP_UPDATE_AVAILABLE: "SET_APP_UPDATE_AVAILABLE",
  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",

  //payments reducer
  FETCH_RECEIPTS: "FETCH_RECEIPTS",
  FETCH_PAYMENTS_CONFIRMATIONS: "FETCH_PAYMENTS_CONFIRMATIONS",
  FETCH_PAYMENTS_DATA: "FETCH_PAYMENTS_DATA",
  UPDATE_REFERENCE_NUMBER: "UPDATE_REFERENCE_NUMBER",
  UPDATE_PAYMENT_DATA: "UPDATE_PAYMENT_DATA",
  UPDATE_PAYMENTS_LOADING: "UPDATE_PAYMENTS_LOADING"
});
