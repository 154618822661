import React from "react";
import { CardBody, Card, Box } from "@chakra-ui/react";
import JSONPretty from "react-json-pretty";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import _ from "lodash";

const PaymentsCard = ({ id }) => {
  const {  paymentData } = useSelector((state) => state.paymentsReducer);

  const { created } = paymentData?.receipt;

  return (
    <Box>
      <strong>Created</strong>:{" "}
      {dayjs.unix(created).format("YYYY-MM-DD HH:mm:ss")}
      <br />
      <JSONPretty data={paymentData} />
    </Box>
  );
};

export default PaymentsCard;
