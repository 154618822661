import { useSelector } from "react-redux";
import FilterForm from "../../components/FilterForm";
import PaginationButtons from "../../components/sslogs/PaginationButtons";

import React, { useEffect, useState } from "react";
import Loader from "../../components/loader";
import LogCard from "../../components/sslogs/LogCard";
import { Box } from "@chakra-ui/react";
import _ from "lodash";

const Logs = () => {
  const { logs, loading, employers } = useSelector(
    (store) => store.defaultReducer
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);

  const perPageRecords = 50;
  const totalPages = Math.ceil(logs.length / perPageRecords);
  const scrollToTop = (top) => {
    window.scrollTo({
      top: top,
      behavior: "smooth", // Optional: adds smooth scrolling behavior
    });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [logs]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * perPageRecords;
    const endIndex = currentPage * perPageRecords;
    const logsClone = _.cloneDeep(logs);
    setData(logsClone.slice(startIndex, endIndex));
    scrollToTop(250);
  }, [logs, currentPage]);

  return (
    <>
      <FilterForm />
      {loading ? (
        <Loader />
      ) : (
        <>
          {logs.length ? (
            <Box mt={5}>
              <PaginationButtons
                currentPage={currentPage}
                onPageChange={(e) => setCurrentPage(e)}
                totalPages={totalPages}
              />
            </Box>
          ) : (
            <></>
          )}

          {data.map((log, i) => {
            return (
              <LogCard
                logData={log}
                index={i + 1 + (currentPage - 1) * perPageRecords}
                key={log.id}
              />
            );
          })}
          {!logs.length && (
            <Box mt={10}>
              <p>No data available in this search</p>
            </Box>
          )}
        </>
      )}
      {logs.length ? (
        <Box mt={5}>
          <PaginationButtons
            currentPage={currentPage}
            onPageChange={(e) => setCurrentPage(e)}
            totalPages={totalPages}
          />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default Logs;
