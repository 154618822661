import { HStack, Button } from "@chakra-ui/react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";

const PaginationButtons = ({ currentPage, totalPages, onPageChange }) => {
  const nextPage = () => {
    let newPage = currentPage + 1;
    if (newPage > totalPages) {
      newPage = 1;
    }
    onPageChange(newPage);
  };

  const prevPage = () => {
    let newPage = currentPage - 1;
    if (newPage < 1) {
      newPage = totalPages;
    }
    onPageChange(newPage);
  };

  const renderPageButtons = () => {
    const pageButtons = [];

    if (totalPages <= 1) {
      return null;
    }

    if (currentPage > 1) {
      pageButtons.push(
        <Button
          key="prev"
          bg="#fff"
          size={{ base: "", md: "sm" }}
          onClick={prevPage}
          leftIcon={<GoChevronLeft fontSize="1.5em" />}
          rounded="2xl"
          color="#1A1A1A"
          border="2px"
          lineHeight="20px"
          fontSize="14px"
          fontWeight="400"
          borderColor="#E2E2E2"
          _active={{ bg: "#F2F2F2", color: "#4C2DB1" }}
          _focus={{ bg: "#F2F2F2", color: "#4C2DB1" }}
        >
          Prev
        </Button>
      );
    }

    if (totalPages <= 10) {
      for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
        pageButtons.push(
          <Button
            key={pageNumber}
            color={pageNumber === currentPage ? "#4C2DB1" : "#999999"}
            _active={{ bg: "#F2F2F2", color: "#4C2DB1" }}
            _focus={{ bg: "#F2F2F2", color: "#4C2DB1" }}
            bg="#fff"
            size="sm"
            onClick={() => {
              onPageChange(pageNumber);
            }}
            rounded="2xl"
          >
            {pageNumber}
          </Button>
        );
      }
    } else {
      if (currentPage > 6) {
        pageButtons.push(
          <Button
            key="1"
            bg="#fff"
            size="sm"
            rounded="2xl"
            onClick={() => {
              onPageChange(1);
            }}
          >
            1
          </Button>,
          <Button key="ellipsis-prev" size="xs" isDisabled>
            ...
          </Button>
        );
      }

      for (
        let pageNumber = Math.max(1, currentPage - 2);
        pageNumber <= Math.min(currentPage + 2, totalPages);
        pageNumber++
      ) {
        pageButtons.push(
          <HStack>
            <Button
              key={pageNumber}
              color={pageNumber === currentPage ? "#4C2DB1" : "#999999"}
              bg="#fff"
              size="sm"
              onClick={() => {
                onPageChange(pageNumber);
              }}
              rounded="2xl"
            >
              {pageNumber}
            </Button>
          </HStack>
        );
      }

      if (currentPage < totalPages - 5) {
        pageButtons.push(
          <HStack>
            <Button key="ellipsis-next" isDisabled size="xs">
              ...
            </Button>
            <Button
              rounded="2xl"
              size="sm"
              fontSize="sm"
              key={totalPages}
              onClick={() => {
                onPageChange(totalPages);
              }}
            >
              {totalPages}
            </Button>
          </HStack>
        );
      }
    }

    if (currentPage < totalPages) {
      pageButtons.push(
        <Button
          key="next"
          onClick={nextPage}
          rounded="2xl"
          color="#1A1A1A"
          bg="#fff"
          size={{ base: "", md: "sm" }}
          border="2px"
          lineHeight="20px"
          fontSize="14px"
          fontWeight="400"
          borderColor="#E2E2E2"
          _active={{ bg: "#F2F2F2", color: "#4C2DB1" }}
          _focus={{ bg: "#F2F2F2", color: "#4C2DB1" }}
          rightIcon={<GoChevronRight fontSize="1.5em" />}
        >
          Next
        </Button>
      );
    }

    return pageButtons;
  };

  return (
    <HStack
      w="full"
      spacing={2}
      justify="center"
      align="center"
      overflowX={{ base: "scroll", md: "hidden" }}
      whiteSpace="nowrap"
    >
      {renderPageButtons()}
    </HStack>
  );
};

export default PaginationButtons;
