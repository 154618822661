import { Flex, Box, useDisclosure, Button } from "@chakra-ui/react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";

const Layout = (props) => {
  const { onOpen } = useDisclosure();

  return (
    <Box>
      {/* Sidebar */}
      <Sidebar />
      <Header onOpen={onOpen} />

      {/* Content Area */}
      <Box p="15px" overflowY="auto" minH="400px" ml="260px">
        {/* render children */}
        {/* do not render below on dashboard route */}
        {props.children}
      </Box>
    </Box>
  );
};

export default Layout;
