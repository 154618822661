import { combineReducers } from "redux";
import defaultReducer from "./defaultReducer";
import authReducer from "./authReducer";
import paymentsReducer from "./paymentsReducer";
import miscReducer from "./miscReducer";

const reducer = combineReducers({
  defaultReducer,
  authReducer,
  paymentsReducer,
  miscReducer
});

export default reducer;
