import { Box } from "@chakra-ui/react";
import InputComponent from "../averly/Input";
import { useDispatch, useSelector } from "react-redux";
import { updateReferenceNumber } from "../../store/actions/paymentsActions";

const ReferenceNumberInput = () => {
  const { referenceNumber } = useSelector((state) => state.paymentsReducer);
  const dispatch = useDispatch();
  const onChange = (value) => {
    dispatch(updateReferenceNumber(value));
  };
  return (
    <Box>
      <InputComponent
        label="Reference Number"
        placeholder="Type Reference Number"
        value={referenceNumber}
        onChange={onChange}
      />
    </Box>
  );
};

export default ReferenceNumberInput;
