import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Switch,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { formatNumber } from "../../../store/helpers";
import { useState } from "react";
const URLStatsTable = ({ dataObj }) => {
  const [showFriendlyNames, setShowFriendlyNames] = useState(false);
  return (
    <>
      <FormControl display="flex" alignItems="center" mt={5} mb={5}>
        <FormLabel htmlFor="email-alerts" mb="0">
          Show friendly names?
        </FormLabel>
        <Switch
          id="friendly-names"
          isChecked={showFriendlyNames}
          onChange={() => setShowFriendlyNames(!showFriendlyNames)}
        />
      </FormControl>
      <Table className="stripedTable" size="sm">
        <Thead>
          <Tr>
            <Th borderRight="1px solid #dadce0">URL</Th>
            <Th borderRight="1px solid #dadce0">Total Logs</Th>
            <Th>Successful</Th>
            <Th>Unsuccessful</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.keys(dataObj).map((key, index) => {
            const {
              numSuccessful,
              numUnsuccessul,
              total,
              pctSuccessful,
              pctUnsuccessul,
              name,
            } = dataObj[key];
            return (
              <Tr key={index}>
                <Td borderRight="1px solid #dadce0">
                  <Text color={"var(--blue)"}>
                    {showFriendlyNames ? name : key}
                  </Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text color={"var(--blue)"}>{formatNumber(total)}</Text>
                </Td>
                <Td>
                  <Text color={"var(--green)"}>
                    {formatNumber(numSuccessful)}

                  </Text>
                  <Text
                      color="gray.500"
                      fontWeight="semibold"
                      fontSize="xs"
                      textTransform="uppercase"
                    >
                      ({pctSuccessful.toFixed(2)}%)
                    </Text>
                </Td>
                <Td>
                  <Text cursor="pointer" color={"var(--red)"}>
                    {formatNumber(numUnsuccessul)}

                  </Text>
                  <Text
                      color="gray.500"
                      fontWeight="semibold"
                      fontSize="xs"
                      textTransform="uppercase"
                    >
                      ({pctUnsuccessul.toFixed(2)}%)
                    </Text>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

export default URLStatsTable;
