import {
  Box,
  Text,
  VStack,
  Divider,
  Tag,
  HStack,
  Button,
  Code,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { CiClock2 } from "react-icons/ci";
import { fetchCompleteLog } from "../../store/actions/defaultAction";

import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { isEmpty } from "lodash";
import JSONPretty from "react-json-pretty";
import { RELIABLE_ENDPOINTS } from "../../store/constants";

const LogCard = ({ logData, index }) => {
  const dispatch = useDispatch();
  const [completeLog, setCompletelog] = useState({});
  const [showCompleteLog, setShowCompleteLog] = useState(false);
  const [beatifyJSON, setBeatifyJSON] = useState(false);
  const [loading, setLoading] = useState(false);

  const { employers } = useSelector((store) => store.defaultReducer);
  const { id, employerNumber, url, statusCode, created } = logData;
  const isReliableEndpoint = RELIABLE_ENDPOINTS.includes(url);
  let { sscStatus } = logData;
  const successfulStatusCodes = [200, 201, 202];
  if (isReliableEndpoint) {
    sscStatus = successfulStatusCodes.includes(Number(statusCode))
      ? "success"
      : "failed";
  }
  let responseBg =
    sscStatus?.toLowerCase() === "success" ? "green.200" : "red.300";
  responseBg = !sscStatus?.length ? "white" : responseBg;
  const formattedDate = dayjs.unix(created).format("MM-DD-YYYY HH:mm:ss");

  const tradeName = employers.find(
    (employer) => String(employer.id) === String(employerNumber)
  )?.tradeName;

  let bgColor = "";
  if (sscStatus?.toLowerCase() === "success") {
    bgColor = "#F1F6F1";
  } else if (sscStatus?.toLowerCase() === "failed") {
    bgColor = "#F4F2F1";
  }

  const getData = async () => {
    let logData = completeLog;
    if (isEmpty(completeLog)) {
      setLoading(true);
      logData = await fetchCompleteLog(dispatch, id);
      setLoading(false);
    }

    if (logData) {
      setCompletelog(logData);
      setShowCompleteLog(!showCompleteLog);
    }
  };

  return (
    <>
      <Box
        border="1px solid"
        borderColor="gray.100"
        borderWidth="1px"
        p="4"
        mt="5"
        borderRadius={10}
        bgColor={bgColor}
      >
        <VStack w="100%" display="flex" alignItems="flex-start">
          <Box w="100%" mb={3}>
            <HStack mb={1}>
              <Tag>#:</Tag>
              <Text>{index}</Text>
            </HStack>

            <HStack mb={1}>
              <Tag>ID:</Tag>
              <Text>{id}</Text>
            </HStack>

            <HStack mb={1}>
              <Tag>EMPLOYER NUMBER:</Tag>
              <Text>{employerNumber}</Text>
              <Tag>TRADE NAME:</Tag>
              <Text color="var(--blue)">{tradeName}</Text>
            </HStack>

            <Divider />
          </Box>
          <Box w="100%">
            <Text fontWeight="bold">REQUEST</Text>
            <HStack mb={1}>
              <Tag>POST</Tag>
              <Text color="gray.600">{url}</Text>
            </HStack>
            <Divider />
          </Box>

          <Box w="100%">
            <HStack mb={1}>
              <Text color="gray.600">RESPONSE</Text>
              <Tag backgroundColor={responseBg}>{sscStatus}</Tag>
            </HStack>

            <HStack mb={1}>
              <Tag>{statusCode}</Tag>
              <Text>{sscStatus}</Text>
            </HStack>
            <Button
              bgColor="var(--green)"
              size="xs"
              color="white"
              mb={2}
              onClick={getData}
              isLoading={loading}
              loadingText="Fetching Log"
            >
              {showCompleteLog ? "Hide Full JSON" : "Show Full JSON"}
            </Button>
            {showCompleteLog && (
              <>
                <Button
                  bgColor="var(--green)"
                  size="xs"
                  color="white"
                  mb={2}
                  onClick={() => {
                    setBeatifyJSON(!beatifyJSON);
                  }}
                  ml={2}
                >
                  {!beatifyJSON ? "Prettify" : "Show Raw"}
                </Button>
                {beatifyJSON ? (
                  <Code w="100%">
                    <JSONPretty
                      id={id}
                      data={JSON.stringify(completeLog)}
                    ></JSONPretty>
                  </Code>
                ) : (
                  <Code w="100%">{JSON.stringify(completeLog)}</Code>
                )}
              </>
            )}
            <Divider />
          </Box>
          <Box w="100%">
            <HStack pt="5" pb="5">
              <CiClock2 />
              <Text color="gray.600">{formattedDate}</Text>
            </HStack>

            <Divider />
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default LogCard;
