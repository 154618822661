import { Box } from "@chakra-ui/react";
import PaymentsTable from "./PaymentsTable";
import { useSelector } from "react-redux";
import _ from "lodash";

const PaymentsComponent = () => {
  const { paymentsData } = useSelector((state) => state.paymentsReducer);
  const { branchId, employerNumber } = useSelector(
    (state) => state.defaultReducer
  );
  const paymentsClone = _.cloneDeep(paymentsData);
  //filter payments by branchId if it exists
  let filteredPayments =
    branchId.length > 0
      ? paymentsClone.filter((a) => a.receipt.branchId === branchId)
      : paymentsClone;

  // filter payments by employerNumber if it exists
  filteredPayments =
    employerNumber.length > 0
      ? filteredPayments.filter((a) => a.receipt.ssc_number === employerNumber)
      : filteredPayments;
  return (
    <Box>
      <PaymentsTable payments={filteredPayments} />
    </Box>
  );
};

export default PaymentsComponent;
