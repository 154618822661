import { useSelector } from "react-redux";
import FilterForm from "../../components/FilterForm";
import _ from "lodash";
import { generateDateArray } from "../../store/helpers";
import dayjs from "dayjs";
import Loader from "../../components/loader";
import { Box, Tag, Text } from "@chakra-ui/react";
import LogsPerUrlTable from "../../components/stats/tables/LogsPerUrlTable";
import {
  RELIABLE_ENDPOINTS,
  SUCCESS_STATUS_CODES,
} from "../../store/constants";

const LogsPerBranch = () => {
  const { branches, organizationId, urls, logs, dateRange, loading } =
    useSelector((store) => store.defaultReducer);
  const datesArray = generateDateArray(dateRange[0], dateRange[1]);
  const branchesClone = _.cloneDeep(branches);
  const logsClone = _.cloneDeep(logs);
  const urlsClone = _.cloneDeep(urls);
  const orgBranches = branchesClone.filter(
    (branch) =>
      branch.organizationId === organizationId &&
      Boolean(branch.ssStatus) === Boolean(true)
  );

  const dataMap = orgBranches.reduce((acc, { id, name }) => {
    acc[id] = { name, logsData: [] };
    return acc;
  }, {});

  Object.keys(dataMap).forEach((branchId) => {
    urlsClone.forEach(({ name, id, url }) => {
      const URLLogs = logsClone.filter(
        (a) =>
          String(a.branchId) === String(branchId) &&
          String(a.url) === String(url)
      );

      let URLsuccessful =
        URLLogs.filter((log) => log.sscStatus?.toLowerCase() === "success")
          .length || 0;

      const isReliableEndpoint = RELIABLE_ENDPOINTS.includes(url);

      if (isReliableEndpoint) {
        URLsuccessful =
          URLLogs.filter((a) =>
            SUCCESS_STATUS_CODES.includes(Number(a.statusCode))
          ).length || 0;
      }

      const URLUnsuccessful = URLLogs?.length - URLsuccessful || 0;

      const datesData = datesArray.map((fullDate) => {
        const day = new Date(fullDate).getDate();
        const month = new Date(fullDate).getMonth();
        const year = new Date(fullDate).getFullYear();
        const dateStr = `${month}-${day}-${year}`;
        //dayjs very inefficient here
        const dayLogs = URLLogs.filter((a) => {
          const d = new Date(a.created * 1000);
          const day = d.getDate();
          const month = d.getMonth();
          const year = d.getFullYear();
          const matchStr = `${month}-${day}-${year}`;
          return matchStr === dateStr;
        });

        let successful =
          dayLogs.filter((log) => log.sscStatus?.toLowerCase() === "success")
            .length || 0;
        if (isReliableEndpoint) {
          successful =
            dayLogs.filter((a) =>
              SUCCESS_STATUS_CODES.includes(Number(a.statusCode))
            ).length || 0;
        }
        const unsuccessful = dayLogs?.length - successful || 0;

        return {
          total: dayLogs.length,
          successful,
          unsuccessful,
          day,
        };
      });
      const urlData = {
        name,
        id,
        total: URLLogs?.length || 0,
        datesData,
        successful: URLsuccessful,
        unsuccessful: URLUnsuccessful,
        pctSuccessful: (URLsuccessful / URLLogs?.length) * 100 || 0,
        pctUnsuccessful: (URLUnsuccessful / URLLogs?.length) * 100 || 0,
      };

      dataMap[branchId].logsData.push(urlData);
    });

    dataMap[branchId]["total"] =
      logsClone.filter((a) => String(a.branchId) === String(branchId))
        ?.length || 0;
  });

  const dataArray = Object.values(dataMap).sort((a, b) => b.total - a.total);

  return (
    <>
      <FilterForm />
      <h1>Logs Per Branch</h1>
      {loading ? (
        <Loader />
      ) : (
        <>
          {dataArray.map((data, i) => {
            const { logsData, name: branchName, total } = data;
            if (!total) {
              return (
                <Box key={i}>
                  <h2>{branchName} Branch</h2>
                  <Text fontSize="sm">No data available for this branch</Text>
                </Box>
              );
            }

            return (
              <Box mt={5} key={i}>
                <h2>
                  {i + 1}. {branchName} Branch
                </h2>
                {logsData?.map((data, i) => {
                  const { name: URLName } = data;
                  if (!data.total) {
                    return (
                      <Box key={i}>
                        <h3>{URLName}</h3>
                        <Tag>
                          <p className="branchTag">{branchName} Branch</p>
                        </Tag>
                        <Text fontSize="sm">
                          No data available for this URL
                        </Text>
                      </Box>
                    );
                  }
                  return (
                    <LogsPerUrlTable
                      data={data}
                      key={i}
                      branchName={branchName}
                    />
                  );
                })}
              </Box>
            );
          })}
        </>
      )}
    </>
  );
};

export default LogsPerBranch;
