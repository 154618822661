import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useState } from "react";
import MultiSelect from 'react-select';
import {
  FormControl,
  FormLabel,
  Text,
  Box
} from "@chakra-ui/react";
import { updateEmployer } from "../../store/actions/defaultAction";

const EmployerSelection = (props) => {
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const { employers, employerNumber } = useSelector((store) => store.defaultReducer );
  // use only first 100 employers
  const firstEmployers = employers.slice(0, 500);
  const datasetToUse = search.length > 2 ? employers : firstEmployers;

  return (
    <FormControl>
      <Box
        w="100%"
        h="56px"
        borderRadius="8px"
        border="1px solid #b0b0b0"

      >
        <Text h="15px" ml={2}  fontSize="12px" color="#6a6a6a">
          Select Employer
        </Text>
        <MultiSelect
          options={datasetToUse.map((a) => ({
            value: a.id,
            label: a.tradeName,
          }))}
          value={
            employerNumber.length
              ? {
                  value: employerNumber,
                  label: employers.find((a) => a.id === employerNumber)
                    .tradeName,
                }
              : ""
          }
          onChange={(e) => {
            dispatch(updateEmployer(e == null ? "" : e.value));
          }}
          onInputChange={(inputValue) => {
            setSearch(inputValue);
          }}
          isClearable={true}
          isSearchable={true}
          placeholder="All Employers"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: "none",
              outline: "none",
              boxShadow: "none",
              paddingLeft: 0,
              marginLeft: 0,
            }),
          }}
        />
      </Box>
    </FormControl>
  );
};

export default EmployerSelection;
