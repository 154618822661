import dayjs from "dayjs";
import { ACTION_TYPES } from "./types";
import { toast } from "react-toastify";
import { fetchData } from "./defaultAction";

export const fetchPayments = (dateRange = null, reference = null) => async (dispatch) => {
  let payload = {
    createdFrom: dateRange
      ? dateRange[0]
      : dayjs().subtract(1, "day").startOf("day").unix(),
    createdTo: dateRange ? dateRange[1] : dayjs().unix(),
  };
  payload = reference ? { ...payload, reference } : payload;

  const actionType = ACTION_TYPES.FETCH_PAYMENTS_DATA;
  const endpoint = "/paycons/old/with-payments";
  const singleResponse = 0;
  toast.info("Fetching payments");
  dispatch(updatePaymentsLoading(true));
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
  dispatch(updatePaymentsLoading(false));
};

// UPDATE_REFERENCE_NUMBER

export const updateReferenceNumber = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_REFERENCE_NUMBER,
    payload,
  });
};

// UPDATE_PAYMENT_DATA
export const updatePaymentData = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_PAYMENT_DATA,
    payload,
  });
}

// UPDATE_PAYMENTS_LOADING
export const updatePaymentsLoading = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_PAYMENTS_LOADING,
    payload,
  });
};