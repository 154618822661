import React, { useEffect, Suspense } from "react";

import { useDispatch } from "react-redux";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ChakraProvider, Flex, extendTheme } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import Layout from "./pages/Layout";
import Logs from "./pages/sslogs/Logs";
import {
  getBranches,
  getEmployers,
  getUrls,
} from "./store/actions/defaultAction";
// end monthly reports

//Login
import Login from "./pages/auth/Login";

// import css
// import css
import "./assets/css/All.css";
import "./assets/css/Print.css";
import "./assets/css/Inputs.css";

import LogsPerURL from "./pages/stats/LogsPerUrl";
import LogsPerBranch from "./pages/stats/LogsPerBranch";
import Payments from "./pages/payments/Payments";
import { getUserDetails, verifyToken } from "./store/actions/authActions";
import Loader from "./components/loader";
import ServiceWorkerRegistration from "./ServiceWorkerComponent";
import { updateAppUpdateAvailable } from "./store/actions/defaultAction";
import { updateLoading } from "./store/actions/defaultAction";
import { fetchPayments } from "./store/actions/paymentsActions";

function App() {
  const { appStatus, userId, accessToken } = useSelector(
    (state) => state.authReducer
  );
  const { organizationId } = useSelector((state) => state.defaultReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyToken());
    // dispatch(getUserDetails({ userId }));
  }, []);
  useEffect(() => {
    if (userId && accessToken) {
      dispatch(getUserDetails({ userId }));
    }
  }, [userId, accessToken]);
  useEffect(() => {
    if (appStatus) {
      dispatch(getEmployers());
      dispatch(getUrls());
      dispatch(getBranches(organizationId));
      dispatch(fetchPayments())
    }
  }, [appStatus]);

  useEffect(() => {
    dispatch(updateAppUpdateAvailable(false));
    dispatch(updateLoading(false));
  }, []);

  const theme = extendTheme({
    fonts: {
      body: "Lato, sans-serif",
      heading: "Lato, sans-serif",
    },
  });

  return (
    <ChakraProvider theme={theme}>
      {!appStatus ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <ServiceWorkerRegistration />
          <Router>
            <Layout>
              <ToastContainer position="bottom-center" />
              <Suspense
                fallback={
                  <Flex justify="center" align="center" h="100vh">
                    <Loader />
                  </Flex>
                }
              >
                <Routes>
                  <Route path="/" element={<Logs />} />
                  <Route path="/payments" element={<Payments />} />
                  <Route path="/logs-per-url" element={<LogsPerURL />} />
                  <Route path="/logs-per-branch" element={<LogsPerBranch />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Suspense>
            </Layout>
          </Router>
        </>
      )}
    </ChakraProvider>
  );
}

export default App;
