import { Box } from "@chakra-ui/react";
import FilterForm from "../../components/FilterForm";
import { useSelector } from "react-redux";
import _ from "lodash";
import PaymentsComponent from "../../components/payments/PaymentsComponent";
import Loader from "../../components/loader";
import DrawerComponent from "../../components/misc/Drawer";

const Payments = () => {
  const { paymentsLoading } = useSelector((state) => state.paymentsReducer);
  return (
    <Box>
      <FilterForm isPayments={1} />
      <DrawerComponent/>
      {paymentsLoading ? <Loader /> : <PaymentsComponent />}
    </Box>
  );
};

export default Payments;