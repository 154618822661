import { useDispatch, useSelector } from "react-redux";
import { Box, Text } from "@chakra-ui/react";
import { updateUrl } from "../../store/actions/defaultAction";
import Select from "./../averly/Select";

const URLSelection = () => {
  const dispatch = useDispatch();
  const { urls, url } = useSelector((store) => store.defaultReducer);

  return (
    <Box>
      <Select
        label={"Select URL"}
        placeholder="All URLs"
        value={url}
        options={urls}
        onChange={(e) => dispatch(updateUrl({ url: e.target.value }))}
      />
    </Box>
  );
};

export default URLSelection;
